::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

/* Remove outline for non-keyboard :focus */
*:focus:not(.focus-visible) {
  outline: none;
}

html, body {
  overscroll-behavior-x: none;
}